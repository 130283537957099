import React from 'react';
import RecipeIngredientType from '../types/RecipeIngredientType';
import Typography from '@mui/material/Typography';
import Measurement from './Measurement';

function IngredientLine(props) {
  //TODO add support for ingredient with no measurement componets but reference measurements
  let lineText = '';

  if (props.recipeIngredient.preDescriptor !== null) {
    lineText = lineText.concat(props.recipeIngredient.preDescriptor.text, ' ');
  }

  if (
    props.recipeIngredient.unit === null &&
    (props.recipeIngredient.measurement === 0 ||
      props.recipeIngredient.measurement > 1)
  ) {
    lineText = lineText.concat(props.recipeIngredient.ingredient.plural, ' ');
  } else {
    lineText = lineText.concat(props.recipeIngredient.ingredient.name, ' ');
  }

  if (props.recipeIngredient.postDescriptor !== null) {
    lineText = lineText.concat(props.recipeIngredient.postDescriptor.text);
  }
  return (
    <React.Fragment>
      <Measurement
        measurementComponents={props.recipeIngredient.measurementComponents}
      />
      <Typography component="span">{lineText}</Typography>
    </React.Fragment>
  );
}

IngredientLine.propTypes = {
  recipeIngredient: RecipeIngredientType
};

export default IngredientLine;

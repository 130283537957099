import React, { useEffect, useState } from 'react';
import { getRecipeBackup, getUsers } from '../ApiCalls';
import RecipeCreateUpdate from './RecipeCreateUpdate';
import SelectRecipe from './SelectRecipe';

const UpdateRecipe = (props) => {
  const [recipeId, setRecipeId] = useState(null);
  const [recipeRaw, setRecipeRaw] = useState(null);
  const [initialData, setInitialData] = useState(null);
  const [users, setUsers] = useState(null);
  const { username, accessToken } = props;

  useEffect(() => {
    if (recipeId) {
      getRecipeBackup(recipeId)
        .then((response) => setRecipeRaw(response.data)) //TODO chs=ange this to get the backup data
        .catch((error) => console.log(error));
    }
  }, [recipeId]);
  useEffect(() => {
    getUsers()
      .then((response) => setUsers(response.data))
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    if (users !== null && recipeRaw !== null) {
      const sourceUser = users.find(
        (user) => user.username === recipeRaw.sourceUsername
      );
      setInitialData({
        name: recipeRaw.name,
        ingredients: recipeRaw.ingredientText,
        method: recipeRaw.methodText,
        sourceUser: sourceUser,
        id: recipeId
      });
    }
  }, [recipeRaw, users, recipeId]);

  const confirm = () => {
    setRecipeId(null);
    setRecipeRaw(null);
  };

  return (
    <div>
      <h1>Update a Recipe</h1>
      <SelectRecipe setChosenRecipeId={setRecipeId} usernameFilter={username} />
      <h4>Note: you can only update your own recipes</h4>
      {recipeRaw && (
        <RecipeCreateUpdate {...{ initialData, confirm, accessToken }} />
      )}
    </div>
  );
};

export default UpdateRecipe;

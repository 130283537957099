import React from 'react';
import PropTypes from 'prop-types';
import MeasurementComponentType from '../types/MeasurementComponentType';
import Typography from '@mui/material/Typography';

function Measurement(props) {
  let measurementText = '';
  if (props.measurementComponents) {
    let measurementComponents = props.measurementComponents.sort(
      (comp1, comp2) => (comp1.componentNumber > comp2.componentNumber ? 1 : -1)
    );

    measurementComponents.forEach((component) => {
      let measurement = component.measurementValue;
      if (measurement != null) {
        measurementText = measurementText.concat(measurement, ' ');
      }
      if (component.unit !== null) {
        let unitText = component.unit.name;
        if (component.unit.primaryDesignator !== null) {
          if (component.pluralUnit) {
            unitText = component.unit.primaryDesignator.plural;
          } else {
            unitText = component.unit.primaryDesignator.name;
          }
          measurementText = measurementText.concat(unitText, ' ');
        }
      }
    });
  }
  return <Typography component="span">{measurementText}</Typography>;
}

Measurement.propTypes = {
  measurementComponents: PropTypes.arrayOf(MeasurementComponentType).isRequired
};

export default Measurement;

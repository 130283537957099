import React from 'react';
import RecipeCreateUpdate from './RecipeCreateUpdate';

const UploadRecipe = ({ accessToken }) => {
  return (
    <div>
      <h1>Upload a Recipe</h1>
      <RecipeCreateUpdate {...{ accessToken }} />
    </div>
  );
};

export default UploadRecipe;

import React from 'react';
import Autocomplete from '@mui/lab/Autocomplete';
import { getUsers } from '../ApiCalls';
import { TextField } from '@mui/material';

const ChooseUser = ({ onUserChange, user, label }) => {
  const [users, setUsers] = React.useState([]);
  React.useEffect(() => {
    getUsers()
      .then((response) => setUsers(response.data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <Autocomplete
      options={users}
      getOptionLabel={(option) => option.firstName}
      noOptionsText="No family member has that name"
      style={{ maxWidth: 300 }}
      onChange={onUserChange}
      value={user}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          style={{ textTransform: 'capitalize' }}
          margin="normal"
          variant="outlined"
        />
      )}
    />
  );
};

export default ChooseUser;

import { Button, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { postGenerateGame, postVerifyGameRound } from '../../ApiCalls';
import RatioCues from './RatioCues';
import SelectRecipe from '../SelectRecipe';
import SubmitScore from './SubmitScore';

const RatioGame = () => {
  // const classes = useStyles();
  const [isGameOngoing, setIsGameOngoing] = useState(false);
  const [wasAnswerSubmitted, setWasAnswerSubmitted] = useState(false);
  const [wasPlayerSubmitted, setWasPlayerSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [round, setRound] = useState();
  const [status, setStatus] = useState();
  const [correctRecipeName, setCorrectRecipeName] = useState();
  const [chosenRecipeId, setChosenRecipeId] = useState();
  const ratioCount = 2; //TODO let the user choose this

  const startGame = async () => {
    const { data } = await postGenerateGame({ ratioCount });
    setRound(data);
    setIsGameOngoing(true);
    setScore(0);
    setWasAnswerSubmitted(false);
    setWasPlayerSubmitted(false);
  };

  const submitAnswer = async (event) => {
    event.preventDefault();
    if (round === undefined || round === null) {
      return;
    }
    const { id: roundId } = round;
    const {
      data: { nextRound, score, status: roundStatus, answer }
    } = await postVerifyGameRound({
      roundId,
      recipeId: chosenRecipeId
    });
    setStatus(roundStatus);
    setIsGameOngoing(roundStatus);
    setScore(score);
    setWasAnswerSubmitted(true);
    if (roundStatus) {
      setRound(nextRound);
    } else {
      setCorrectRecipeName(answer?.name);
    }
  };

  return (
    <div>
      <h1>The Ratio Game</h1>
      <div>
        <Button
          onClick={startGame}
          variant="contained"
          color="primary"
          sx={{ margin: 1 }}
        >
          {isGameOngoing ? 'Restart' : 'Start'}
        </Button>
        <Typography variant="h5" component="span">
          Score: {score}
        </Typography>
      </div>
      <br />
      {wasAnswerSubmitted && (
        <Typography variant="h5" component="div">
          {status
            ? 'Correct, round passed'
            : `Incorrect Game Over. One possible answer was ${correctRecipeName}`}
        </Typography>
      )}
      <br />
      <RatioCues {...{ round }}></RatioCues>
      {isGameOngoing && (
        <form onSubmit={submitAnswer}>
          <SelectRecipe {...{ setChosenRecipeId }}></SelectRecipe>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </form>
      )}
      <br />
      <br />
      {wasAnswerSubmitted && !status && !wasPlayerSubmitted && score !== 0 && (
        <SubmitScore
          gameId={round.game.id}
          submitSuccessfull={() => setWasPlayerSubmitted(true)}
        />
      )}
    </div>
  );
};

export default RatioGame;

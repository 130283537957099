import React, { useEffect, useState } from 'react';
import { deleteRecipe, getRecipe, postParse } from '../ApiCalls';
import RecipeParse from './RecipeParse';
import Recipe from './Recipe';
import RecipeSubmitButtons from './RecipeSubmitButtons';
import RecipeUploadAlert from './RecipeUploadAlert';

const RecipeCreateUpdate = (props) => {
  const { initialData, confirm, accessToken } = props;
  const blankState = {
    name: '',
    ingredients: '',
    method: '',
    sourceUser: null,
    existingRecipeId: null,
    recipe: null,
    errorMessage: '',
    failedParse: false
  };

  const [name, setName] = React.useState(
    initialData ? initialData.name : blankState.name
  );
  const [ingredients, setIngredients] = React.useState(blankState.ingredients);
  const [method, setMethod] = React.useState(blankState.method);
  const [sourceUser, setSourceUser] = React.useState(blankState.sourceUser);
  const [existingRecipeId, setExistingRecipeId] = React.useState(
    blankState.existingRecipeId
  );
  const [recipe, setRecipe] = React.useState(blankState.recipe);
  const [errorMessage, setErrorMessage] = React.useState(
    blankState.errorMessage
  );
  const [failedParse, setFailedParse] = React.useState(blankState.failedParse);
  const [inputCahnged, setInputChanged] = useState(false);
  const [recipeSubmitted, setRecipeSubmitted] = useState(false);

  const recipeExists = recipe !== null;

  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
      setIngredients(initialData.ingredients);
      setMethod(initialData.method);
      setSourceUser(initialData.sourceUser);
      setExistingRecipeId(initialData.id);
    }
  }, [initialData]);

  const clearState = () => {
    setName(blankState.name);
    setIngredients(blankState.ingredients);
    setMethod(blankState.method);
    setSourceUser(blankState.sourceUser);
    setExistingRecipeId(blankState.existingRecipeId);
    setRecipe(blankState.recipe);
    setErrorMessage(blankState.errorMessage);
    setFailedParse(blankState.failedParse);
    setRecipeSubmitted(false);
  };

  const handleNameChange = (event) => {
    setInputChanged(true);
    setName(event.target.value);
  };
  const handleIngredientsChange = (event) => {
    setInputChanged(true);
    setIngredients(event.target.value);
  };
  const handleMethodChange = (event) => {
    setMethod(event.target.value);
    setInputChanged(true);
  };
  const handleSourceUserChange = (event, value) => {
    setInputChanged(true);
    setSourceUser(value);
  };
  const handleSubmit = (event) => {
    setErrorMessage('');
    setFailedParse(blankState.failedParse);
    if (name === blankState.name || sourceUser === blankState.sourceUser) {
      setErrorMessage('Recipe name and family member name are required');
    } else {
      let data = {
        name: name,
        ingredientText: ingredients,
        methodText: method,
        sourceUserId: sourceUser.id
      };
      postParse(data)
        .then((response) => {
          if (existingRecipeId !== null) {
            deleteRecipe(existingRecipeId, accessToken); //TODO maybe handel error case
          }
          setExistingRecipeId(response.data);
          setInputChanged(false);
          setRecipeSubmitted(true);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setErrorMessage(error.response.data.message);
          } else {
            setErrorMessage('Something went wrong please click submit again');
          }
          setFailedParse(true);
        });
    }
    event.preventDefault();
  };

  React.useEffect(() => {
    if (existingRecipeId) {
      getRecipe(existingRecipeId)
        .then((response) => setRecipe(response.data))
        .catch((error) => console.log(error));
    }
  }, [existingRecipeId]);

  const alert = (
    <RecipeUploadAlert
      errorMessage={errorMessage}
      recipeSubmitted={recipeSubmitted}
      failedParse={failedParse}
    />
  );
  const submitButtons = (
    <RecipeSubmitButtons
      recipeExists={recipeExists}
      clearState={confirm || clearState}
      disableConfirm={inputCahnged}
    />
  );

  return (
    <div>
      {!recipeExists || <Recipe recipe={recipe} background={true} />}
      <form onSubmit={handleSubmit}>
        {recipeExists && submitButtons}
        {recipeExists && alert}
        <RecipeParse
          handleNameChange={handleNameChange}
          handleIngredientsChange={handleIngredientsChange}
          handleMethodChange={handleMethodChange}
          handleSourceUserChange={handleSourceUserChange}
          name={name}
          ingredients={ingredients}
          sourceUser={sourceUser}
          method={method}
        />
        {!recipeExists && alert}
        {!recipeExists && submitButtons}
      </form>
    </div>
  );
};

export default RecipeCreateUpdate;

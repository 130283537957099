import { Typography } from '@mui/material';
import React from 'react';

const RatioCues = ({ round }) => {
  return (
    round?.ratios?.map(
      ({
        ingredient1,
        ingredient2,
        measurement1,
        measurement2,
        measurementType,
        id
      }) => (
        <Typography key={id} component="div" Typography variant="h6">
          The ratio of {ingredient1.name} to {ingredient2.name} is{' '}
          {+measurement1.toFixed(2)} to {+measurement2.toFixed(2)} by{' '}
          {measurementType.name}
        </Typography>
      )
    ) ?? null
  );
};

export default RatioCues;

import PropTypes from 'prop-types';
import MeasurementSystemType from './MeasurementSystemType';
import MeasurementTypeType from './MeasurementTypeType';

const lazyUnitType = function () {
  return UnitType.apply(this, arguments);
};
const UnitType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  primaryDesignator: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    plural: PropTypes.string.isRequired
  }),
  measurementSystem: MeasurementSystemType,
  measurementType: MeasurementTypeType,
  referenceMeasurement: PropTypes.number.isRequired,
  referenceUnit: lazyUnitType //TODO is there a better way than making this optional to handle the fact that this is omitted one level down in the json
});

export default UnitType;

import { Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { postAddGuestToGame, postAddUserToGame } from '../../ApiCalls';
import ChooseUser from '../ChooseUser';

const SubmitScore = ({ gameId, submitSuccessfull }) => {
  // const name = gamePlayer?.user?.firstName ?? gamePlayer?.guest?.name;

  const [user, setUser] = useState();
  const [guestName, setGuestName] = useState();

  const submitGuest = async (event) => {
    event.preventDefault();
    postAddGuestToGame({ name: guestName, gameId }).then(() =>
      submitSuccessfull()
    );
  };
  const submitUser = async (event) => {
    event.preventDefault();
    const { username } = user;
    postAddUserToGame({ username, gameId }).then(() => submitSuccessfull());
  };

  return (
    <div>
      <Typography component="div" Typography variant="h5">
        Submit Score
      </Typography>
      <br />
      {/* {gamePlayer && <Button onclick={submitPlayer}>I am {name}</Button>} */}
      <form onSubmit={submitUser}>
        <Typography component="div">
          If you are are a family member choose your name from the list below
        </Typography>
        <ChooseUser
          onUserChange={(event, value) => setUser(value)}
          user={user}
          label="Find Yourself"
        />
        <Button type="submit" variant="contained" color="primary">
          Submit User
        </Button>
      </form>
      <br />
      <form onSubmit={submitGuest}>
        <Typography component="div">
          Or if are you are a guest then submit your name below
        </Typography>
        <div>
          <TextField
            name="name"
            placeholder="Name"
            label="Name"
            // fullWidth={true}
            variant="outlined"
            margin="normal"
            value={guestName}
            onChange={({ target: { value } }) => setGuestName(value)}
          />
        </div>
        <Button type="submit" variant="contained" color="primary">
          Submit Guest Name
        </Button>
      </form>
    </div>
  );
};

export default SubmitScore;

import PropTypes from 'prop-types';
import UnitType from './UnitType';
import IngredientDescriptorType from './IngredientDescriptorType';
import IngredientType from './IngredientType';
import MeasurementComponentType from './MeasurementComponentType';

const RecipeIngredientType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  measurement: PropTypes.number,
  ingredient: IngredientType.isRequired,
  unit: UnitType,
  preDescriptor: IngredientDescriptorType,
  postDescriptor: IngredientDescriptorType,
  measurementComponents: PropTypes.arrayOf(MeasurementComponentType).isRequired,
  ingredientNumber: PropTypes.number
});

export default RecipeIngredientType;

import React, { useEffect, useState } from 'react';
import Recipe from './Recipe';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import Box from '@mui/material/Box';
import { getMeasurementSystems, getRecipe } from '../ApiCalls';
import SelectRecipe from './SelectRecipe';

const RecipeDisplay = () => {
  const [measurementSystems, setMeasurementSystems] = useState([]);
  const [multiplier, setMultiplier] = useState('');
  const [chosenSystem, setChosenSystem] = useState(null);
  const [recipe, setRecipe] = useState(null);
  const [recipeId, setRecipeId] = useState(null);

  useEffect(() => {
    getMeasurementSystems().then((response) =>
      setMeasurementSystems(
        response.data.sort((a, b) => a.name.localeCompare(b.name))
      )
    );
  }, []);
  useEffect(() => {
    if (recipeId) {
      getRecipe(recipeId)
        .then((response) => setRecipe(response.data))
        .catch((error) => console.log(error));
    }
  }, [recipeId]);

  // TODO add regex validation on frontend for multiplier
  const handleMultiplierChange = (event) => {
    setMultiplier(event.target.value);
    if (chosenSystem === null && event.target.value) {
      let defaultSystem = measurementSystems[1]; // TODO change this hardcode
      setChosenSystem(defaultSystem);
    }
  };
  const handleSystemChange = (event, value) => {
    setChosenSystem(value);
    if (multiplier === '' && value) {
      setMultiplier('1');
    }
  };
  // TODO: cleanup styles and check why i need to set max and minwidth instead of width

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <SelectRecipe setChosenRecipeId={setRecipeId} />
        <div
          style={{
            verticalAlign: 'top',
            maxWidth: 250,
            minWidth: 250,
            flexGrow: 1,
            padding: 15
          }}
        >
          <Box fontWeight="fontWeightBold">Modify Measurements</Box>
          <Autocomplete
            options={measurementSystems}
            getOptionLabel={(option) => option.name}
            noOptionsText="Cannot find that measurement system"
            size="small"
            value={chosenSystem}
            onChange={handleSystemChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Measurement System"
                margin="normal"
                variant="outlined"
              />
            )}
          />
          <TextField
            placeholder="Example: 1 1/2 or 1.5"
            label="Ingredient Multiplier"
            variant="outlined"
            margin="normal"
            size="small"
            minRows={1}
            value={multiplier}
            onChange={handleMultiplierChange}
          />
        </div>
      </div>
      <Recipe
        recipe={recipe}
        measurementSystem={chosenSystem}
        multiplier={multiplier}
      />
    </div>
  );
};

export default RecipeDisplay;

import Axios from 'axios';

const baseUrl = new URL(process.env.REACT_APP_API_URL);

// returns a promise
export function getRecipe(id) {
  return Axios.get(new URL(`recipe/${id}`, baseUrl));
}

// returns a promise
export function getRecipeBackup(id) {
  return Axios.get(new URL(`recipe-backup/${id}`, baseUrl));
}

// returns a promise
export function getRecipes() {
  return Axios.get(new URL('recipe', baseUrl));
}

// returns a promise
export function getRecipeMetaData() {
  return Axios.get(new URL('recipe-meta', baseUrl));
}

// returns a promise
export function deleteRecipe(id, accessToken) {
  void accessToken;
  return Axios({
    method: 'delete',
    url: new URL(`recipe/${id}`, baseUrl)
    // headers: { Authorization: `Bearer ${accessToken}` } // TODO add authorization properly
  });
}

// returns a promise
export function getMeasurementSystems() {
  return Axios.get(new URL('measurement-system', baseUrl));
}

// returns a promise
export function getUsers() {
  return Axios.get(new URL('user', baseUrl));
}

export function postIngredient(ingredientName) {
  return Axios({
    method: 'post',
    url: new URL('ingredient', baseUrl),
    data: ingredientName,
    headers: { 'Content-Type': 'text/html' }
  });
}

// returns a promise
export function postParse(data) {
  return Axios({
    method: 'post',
    url: new URL('parse', baseUrl),
    data,
    headers: { 'Content-Type': 'application/json' }
  });
}

// returns a promise
export function postMeasurements(data) {
  return Axios({
    method: 'post',
    url: new URL('measurements', baseUrl),
    data,
    headers: { 'Content-Type': 'application/json' }
  });
}

export function postGenerateGame(data) {
  return Axios({
    method: 'post',
    url: new URL('ratio-game/generate', baseUrl),
    data,
    headers: { 'Content-Type': 'application/json' }
  });
}

export function postVerifyGameRound(data) {
  return Axios({
    method: 'post',
    url: new URL('ratio-game/verify', baseUrl),
    data,
    headers: { 'Content-Type': 'application/json' }
  });
}

export function postAddGuestToGame(data) {
  return Axios({
    method: 'post',
    url: new URL('ratio-game/guest', baseUrl),
    data,
    headers: { 'Content-Type': 'application/json' }
  });
}

export function postAddUserToGame(data) {
  return Axios({
    method: 'post',
    url: new URL('ratio-game/user', baseUrl),
    data,
    headers: { 'Content-Type': 'application/json' }
  });
}

export function getLeaderboard() {
  return Axios.get(new URL('ratio-game/leaderboard', baseUrl));
}

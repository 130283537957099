import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import RecipeDisplay from '../RecipeDisplay';
import Contact from '../Contact';
import TopBar from './Topbar';
import { useAuth0 } from '@auth0/auth0-react';
import UploadRecipe from '../UploadRecipe';
import UpdateRecipe from '../UpdateRecipe';
import React, { useEffect, useState } from 'react';
import RatioGame from '../RatioGame';
import HighScores from '../HighScores';
import LogoutButton from './Topbar/LogoutButton';
import LoginButton from './Topbar/LoginButton';

const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  headerSpacer: {
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}));

const Navigator = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [pageId, setPageId] = useState(0); //default page is Recipes
  const [accessToken, setAccessToken] = useState();
  // user.name is not the actual name in auth0 since I have an
  //     auth0 rule that sets name to username
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    getAccessTokenWithPopup
  } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      const tokenOptions = {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'crud:all'
      };
      getAccessTokenSilently(tokenOptions)
        .then((token) => setAccessToken(token))
        .catch(() =>
          getAccessTokenWithPopup(tokenOptions).then((token) =>
            setAccessToken(token)
          )
        );
    }
  }, [getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const authenticatedPages = [
    {
      title: 'Update a Recipe',
      component: (
        <UpdateRecipe accessToken={accessToken} username={user && user.name} />
      )
    }
  ];

  let pages = [
    { title: 'Recipes', component: <RecipeDisplay /> },
    {
      title: 'Upload Recipe',
      component: <UploadRecipe {...{ accessToken }} />
    },
    { title: 'Ratio Game', component: <RatioGame /> },
    { title: 'High Scores', component: <HighScores /> }
    // { title: "Create Ingredient", component : <CreateIngredient/>}
  ];
  pages = isAuthenticated ? pages.concat(authenticatedPages) : pages;
  pages = pages.concat([{ title: 'Contact Us', component: <Contact /> }]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <TopBar handleDrawerOpen={handleDrawerOpen} open={open} />
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          {isAuthenticated ? <LogoutButton /> : <LoginButton />}
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {pages.map((pageInfo, index) => (
            <ListItem
              button
              key={pageInfo.title}
              onClick={() => setPageId(index)}
            >
              <ListItemText primary={pageInfo.title} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.headerSpacer} />
        {pageId !== undefined && pages[pageId].component}
      </main>
    </div>
  );
};

export default Navigator;

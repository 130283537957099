import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getLeaderboard } from '../ApiCalls';

const levels = [
  { name: 'Mushroom', minimumScore: 1 },
  { name: 'Peas', minimumScore: 2 },
  { name: 'Carrot', minimumScore: 5 },
  { name: 'Broccoli', minimumScore: 10 },
  { name: 'Eggplant', minimumScore: 15 },
  { name: 'Asparagus', minimumScore: 20 },
  { name: 'Veggie Casserole', minimumScore: 25 }
];

const HighScores = () => {
  const [scoresData, setScoresData] = useState();
  useEffect(() => {
    getLeaderboard().then(({ data }) => setScoresData(data));
  }, []);

  const getLevel = (score) =>
    levels
      .filter(({ minimumScore }) => score >= minimumScore)
      .sort(
        ({ minimumScore: minimumScore1 }, { minimumScore: minimumScore2 }) =>
          minimumScore2 - minimumScore1
      )[0]?.name;

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h4">Name</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h4">Score</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h4">Level</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scoresData
            ?.sort(({ score: score1 }, { score: score2 }) => score2 - score1)
            .map(({ name, score }, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="h6">{name}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">{score}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">{getLevel(score)}</Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HighScores;

import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import UserType from '../types/UserType';
import FormLabel from '@mui/material/FormLabel';
import { makeStyles } from '@mui/styles';
import ChooseUser from './ChooseUser';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0)
  }
}));

function RecipeParse(props) {
  const classes = useStyles();

  return (
    <div>
      <TextField
        name="name"
        placeholder="Recipe Name"
        label="Recipe Name"
        fullWidth={true}
        variant="outlined"
        margin="normal"
        value={props.name}
        onChange={props.handleNameChange}
      />
      <ChooseUser
        onUserChange={props.handleSourceUserChange}
        user={props.sourceUser}
        label="Recipe Source"
      />
      <FormLabel component="legend" className={classes.formLabel}>
        Any line that represents a section heading must start with @@ (eg. @@For
        the Sauce:)
      </FormLabel>
      <TextField
        name="ingredients"
        placeholder="List ingredients one per line"
        label="Ingredients"
        fullWidth={true}
        variant="outlined"
        margin="normal"
        multiline
        minRows={4}
        maxRows={100}
        value={props.ingredients}
        onChange={props.handleIngredientsChange}
      />
      <TextField
        name="method"
        placeholder="List each instruction or paragraph on a different line"
        label="Methods"
        fullWidth={true}
        variant="outlined"
        margin="normal"
        multiline
        minRows={4}
        maxRows={100}
        value={props.method}
        onChange={props.handleMethodChange}
      />
    </div>
  );
}

RecipeParse.propTypes = {
  handleNameChange: PropTypes.func.isRequired,
  handleIngredientsChange: PropTypes.func.isRequired,
  handleMethodChange: PropTypes.func.isRequired,
  handleSourceUserChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  ingredients: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  sourceUser: UserType.isRequired
};

export default RecipeParse;

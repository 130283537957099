import PropTypes from 'prop-types';
import UnitType from './UnitType';

const MeasurementComponentType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  measurementValue: PropTypes.string.isRequired,
  unit: UnitType,
  pluralUnit: PropTypes.bool.isRequired,
  componentNumber: PropTypes.number.isRequired,
  recipeIngredientId: PropTypes.number.isRequired
});

export default MeasurementComponentType;

import { Box, TextField } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { getRecipeMetaData } from '../ApiCalls';

const SelectRecipe = (props) => {
  const [recipeMetaData, setRecipeMetaData] = useState([]);
  const { setChosenRecipeId, usernameFilter } = props;

  useEffect(() => {
    getRecipeMetaData()
      .then((response) =>
        setRecipeMetaData(
          response.data
            .filter((meta) => meta.id >= 4)
            .filter(
              (meta) =>
                !usernameFilter || meta.sourceUsername === usernameFilter
            )
        )
      )
      .catch((error) => console.log(error));
    // this excludes test recipes
  }, [usernameFilter]);

  return (
    <div
      style={{ verticalAlign: 'top', maxWidth: 400, flexGrow: 1, padding: 15 }}
    >
      <Box fontSize={18} fontWeight="fontWeightBold">
        Search for a Recipe
      </Box>
      <Autocomplete
        options={recipeMetaData}
        getOptionLabel={(option) => option.name}
        noOptionsText="No recipes with that name found"
        style={{ minWidth: 300 }}
        //TODO controll input so i can clear it from parent
        onChange={(_changeEvent, value) => {
          if (value) {
            setChosenRecipeId(value.id);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Recipe Name"
            margin="normal"
            variant="outlined"
          />
        )}
      />
    </div>
  );
};

export default SelectRecipe;

import { Button } from '@mui/material';
import * as React from 'react';

const RecipeSubmitButtons = (props) => {
  const { recipeExists, clearState, disableConfirm } = props;
  let submitButton = (
    <Button type="submit" variant="contained" color="primary">
      {recipeExists ? 'Update' : 'Submit'}
    </Button>
  );

  return !recipeExists ? (
    submitButton
  ) : (
    <div>
      {submitButton}
      <Button
        variant="contained"
        color="primary"
        disabled={disableConfirm}
        sx={{ margin: 1 }}
        onClick={clearState}
      >
        Done
      </Button>
    </div>
  );
};

export default RecipeSubmitButtons;

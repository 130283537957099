import PropTypes from 'prop-types';

const IngredientSectionType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  firstIngredientNumber: PropTypes.number
  // this is missing the recipe property since this is currently only found nested in a recipe
});

export default IngredientSectionType;

import React from 'react';
import IngredientLine from './IngredientLine';
import RecipeType from '../types/RecipeType';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Measurement from './Measurement';
import { postMeasurements } from '../ApiCalls';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import MeasurementSystemType from '../types/MeasurementSystemType';
import TableHead from '@mui/material/TableHead';
import Box from '@mui/material/Box';

function Recipe(props) {
  const [modifiedMeasurements, setModifiedMeasurements] = React.useState([]);
  React.useEffect(() => {
    if (props.recipe && props.measurementSystem && props.multiplier) {
      postMeasurements({
        recipeId: props.recipe.id,
        measurementSystemId: props.measurementSystem.id,
        multiplier: props.multiplier
      })
        .then((response) => setModifiedMeasurements(response.data))
        .catch((error) => console.log(error));
    } else {
      setModifiedMeasurements([]);
    }
  }, [props.recipe, props.multiplier, props.measurementSystem]);

  if (!props.recipe) {
    return <div></div>;
  }

  let paragraphs = [];
  if (props.recipe.methodParagraphs !== null) {
    paragraphs = props.recipe.methodParagraphs.sort((par1, par2) =>
      par1.paragraphNum > par2.paragraphNum ? 1 : -1
    );
  }
  let method = [];
  for (let i = 0; i < paragraphs.length; i++) {
    let stepText = '';
    if (props.recipe.numberedSteps) {
      stepText = stepText.concat(paragraphs[i].paragraphNum, '. ');
    }
    stepText = stepText.concat(paragraphs[i].text);
    method.push(<ListItemText primary={stepText}></ListItemText>);
  }
  const measurementStyle = {
    width: 12,
    borderBottom: 'none',
    whiteSpace: 'nowrap'
  };
  const ingredientLineStyle = { borderBottom: 'none', whiteSpace: 'nowrap' };
  let tableHeader = <TableHead></TableHead>;
  if (Array.isArray(modifiedMeasurements) && modifiedMeasurements.length) {
    tableHeader = (
      <TableHead>
        <TableRow>
          <TableCell key={0} style={measurementStyle}>
            <Box fontWeight="fontWeightBold" fontSize={15}>
              New Measurements
            </Box>
          </TableCell>
          <TableCell key={0} style={ingredientLineStyle}>
            <Box fontWeight="fontWeightBold" fontSize={15}>
              Original
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  let sectionHeaders = {};
  props.recipe.ingredientSections.forEach((section) => {
    let sectionHeader = (
      <TableRow key={'header' + section.firstIngredientNumber}>
        <TableCell key={0} colSpan={2} style={measurementStyle}>
          <Box sx={{ fontSize: 15, fontWeight: 'bold' }} mx={2}>
            {section.name}
          </Box>
        </TableCell>
      </TableRow>
    );
    sectionHeaders[section.firstIngredientNumber] = sectionHeader;
  });

  let backgroundStyle = {};
  if (props.background === true) {
    backgroundStyle = {
      backgroundColor: '#F0F0F0',
      borderRadius: 5,
      padding: 10,
      marginTop: 10,
      marginBottom: 10
    };
  }

  let orderedIngredients = props.recipe.recipeIngredients.sort(
    (i1, i2) => i1.ingredientNumber - i2.ingredientNumber
  );
  let trailingSection;
  let lastIngredientNumber =
    orderedIngredients[orderedIngredients.length - 1].ingredientNumber;
  if (lastIngredientNumber + 1 in sectionHeaders) {
    trailingSection = sectionHeaders[lastIngredientNumber + 1];
  }

  return (
    <div style={backgroundStyle}>
      <h1>{props.recipe.name}</h1>
      {props.recipe.sourceUser && (
        <h3>
          From:{' '}
          {props.recipe.sourceUser.firstName +
            ' ' +
            props.recipe.sourceUser.lastName}
        </h3>
      )}
      <h3>Ingredients:</h3>
      <TableContainer>
        <Table size="small">
          {tableHeader}
          <TableBody>
            {orderedIngredients.map((recipeIngredient) => (
              <React.Fragment key={recipeIngredient.id}>
                {recipeIngredient.ingredientNumber in sectionHeaders &&
                  sectionHeaders[recipeIngredient.ingredientNumber]}
                <TableRow key={recipeIngredient.id}>
                  <TableCell key={0} style={measurementStyle}>
                    <Measurement
                      measurementComponents={modifiedMeasurements.filter(
                        (meas) =>
                          meas.recipeIngredient.id === recipeIngredient.id
                      )}
                    />
                  </TableCell>
                  <TableCell key={1} style={ingredientLineStyle}>
                    <IngredientLine recipeIngredient={recipeIngredient} />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
            {trailingSection}
          </TableBody>
        </Table>
      </TableContainer>
      <h3>Method:</h3>
      <List>{method}</List>
    </div>
  );
}

Recipe.propTypes = {
  recipe: RecipeType,
  measurementSystem: MeasurementSystemType,
  multiplier: PropTypes.number,
  background: PropTypes.bool
};

export default Recipe;

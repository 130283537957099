import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { postIngredient } from '../ApiCalls';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

function CreateIngredient() {
  const classes = useStyles();

  const [ingredientId, setIngredientId] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [name, setName] = React.useState('');
  const handleNameChange = (event) => setName(event.target.value);
  const handleSubmit = (event) => {
    setErrorMessage('');
    setIngredientId(null);
    postIngredient(name)
      .then((response) => setIngredientId(response.data))
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('Please try again');
        }
      });
    event.preventDefault();
  };

  let alertElement = <React.Fragment></React.Fragment>;
  if (errorMessage !== '') {
    alertElement = (
      <Box
        component="span"
        fontWeight="fontWeightBold"
        fontSize={18}
        style={{ color: 'red' }}
      >
        {errorMessage}
      </Box>
    );
  } else if (ingredientId != null) {
    alertElement = (
      <Box
        component="span"
        fontWeight="fontWeightBold"
        fontSize={18}
        style={{ color: 'green' }}
      >
        Ingredient Created
      </Box>
    );
  }

  return (
    <div>
      <TextField
        placeholder="Example: squid"
        label="New Ingredient"
        fullWidth={false}
        variant="outlined"
        size="small"
        value={name}
        onChange={handleNameChange}
        className={classes.textField}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ margin: 1 }}
        onClick={handleSubmit}
      >
        Add Ingredient
      </Button>
      {alertElement}
    </div>
  );
}

export default CreateIngredient;

import PropTypes from 'prop-types';
import RecipeIngredientType from './RecipeIngredientType';
import MethodParagraphType from './MethodParagraphType';
import UserType from './UserType';
import IngredientSectionType from './IngredientSectionType';

// TODO fix recipe field of prop types
const RecipeType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  numberedSteps: PropTypes.bool.isRequired,
  methodParagraphs: PropTypes.arrayOf(MethodParagraphType).isRequired,
  ingredientSections: PropTypes.arrayOf(IngredientSectionType).isRequired,
  recipeIngredients: PropTypes.arrayOf(RecipeIngredientType).isRequired,
  sourceUser: UserType
});

export default RecipeType;

import React from 'react';
import '../App.css';
import Navigator from './Navigator';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { grey, indigo } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: indigo,
    secondary: grey
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header className="App-header"></header>
        <Navigator />
      </div>
    </ThemeProvider>
  );
}

export default App;

import { Box } from '@mui/material';
import Alert from '@mui/lab/Alert';
import React from 'react';
import CreateIngredient from './CreateIngredient';

const RecipeUploadAlert = (props) => {
  const { errorMessage, recipeSubmitted, failedParse } = props;

  return (
    <>
      {errorMessage !== '' && (
        <Box my={2}>
          {failedParse && <CreateIngredient />}
          <Alert margin="normal" severity="error">
            {errorMessage}
          </Alert>
        </Box>
      )}
      {recipeSubmitted && errorMessage === '' && (
        <Box my={2}>
          <Alert margin="normal" severity="success">
            Upload was successful
          </Alert>
        </Box>
      )}
    </>
  );
};

export default RecipeUploadAlert;

import { makeStyles } from '@mui/styles';
import { Grid, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  }
}));

const TopBar = ({ handleDrawerOpen, open }) => {
  const classes = useStyles();
  return (
    <Toolbar>
      <Grid
        justifyContent="space-between"
        wrap="nowrap"
        container
        alignItems="center"
      >
        <Grid item xs={1}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid item xs={11}>
          <Typography variant="h5" align="center">
            The Bohm and Gillis Families
          </Typography>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default TopBar;
